import * as React from 'react';
import queryString from 'query-string';
import useSWR from 'swr';
import { request } from 'graphql-request';

// Require the presentation component here, this is shared between the SSR template
// and the runtime preview template
import Page from '../../templates/presentation/service';

// Get the variables for the query
const variables =
  typeof window !== 'undefined'
    ? {
        id: queryString.parse(window.location.search).id,
      }
    : {};

const fetcher = (query) => request(process.env.GATSBY_WP_API, query, variables);

const ServicePreview = () => {
  const { data, error } = useSWR(
    `
  query ServicePreview($id: ID!) {
    service(id: $id, idType: DATABASE_ID) {
      id
      content
      title
      slug
      serviceAcf {
        pageIntroduction
        type
        heroUnit {
          heroImage {
            sourceUrl
          }
        }
        keyContacts {
          ... on StaffMember {
            id
            title
            slug
            staffCard {
              cardSummaryText
              hideProfileLink
              keyServices {
                keyService
              }
            }
            staffAcf {
              profileImage {
                sourceUrl
              }
            }
          }
        }
      }
      wpParent: parent {
        node {
          ... on Service {
            id
            title
            slug
            serviceAcf {
              heroUnit {
                heroImage {
                  sourceUrl
                }
              }
            }
            wpChildren: children {
              nodes {
                ... on Service {
                  id
                  title
                  slug
                  wpParent: parent {
                    node {
                      id
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
      wpChildren: children {
        nodes {
          ... on Service {
            id
            title
            slug
            serviceAcf {
              type
            }
            wpParent: parent {
              node {
                id
                slug
              }
            }
          }
        }
      }
    }
  }
  `,
    fetcher
  );

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  return <Page data={data.service} isPreview />;
};

export default ServicePreview;
